



















































import AutoReplyActions from './AutoReplyActions.vue'
import PrivateMessagesSettings from '@/views/board-settings/private-messages-settings.vue'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import PmCommands from './PmCommands.vue'
import moment from 'moment'

import { Component, Mixins } from 'vue-property-decorator'

enum BotFeedbackTab {
  Settings='settings',
  AutoReply='autoReply',
  Command='command'
}

@Component({
  components: {
    PrivateMessagesSettings,
    PmCommands,
    AutoReplyActions
  },
  data() {
    return {
      BotFeedbackTab,
      moment,
    }
  }
})
export default class BotFeedback extends Mixins<DrawerWidthMixin>(DrawerWidthMixin) {
  activeTab: BotFeedbackTab = BotFeedbackTab.Settings

  goToBotFeedbackId(): void {
    this.activeTab = BotFeedbackTab.Settings

    this.$router.replace({
      name: 'Bot_feedback',
      query: {
        tab: BotFeedbackTab.Settings
      },
      hash: '#feedback_group_id'
    })
  }

  changeTab(tab: BotFeedbackTab): void {
    this.activeTab = tab
    this.$router.replace({
      name: 'Bot_feedback',
      query: {
        tab: tab
      }
    }).catch(() => {})
  }

  mounted():void {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        if (this.$store.state.boardsState.activeBoard?.config.pm_config.initial_message === null) {
          this.$store.state.boardsState.activeBoard.config.pm_config.initial_message = []
        }

        if (this.$store.state.boardsState.activeBoard?.config.pm_config.user_auto_reply_actions === null) {
          this.$store.state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions = []
        }

        if (this.$store.state.boardsState.activeBoard?.config.pm_config.commands === null) {
          this.$store.state.boardsState.activeBoard.config.pm_config.commands = []
        }
      }
    })

    const tabQuery = this.$route.query.tab

    if (tabQuery) {
      this.activeTab = tabQuery as BotFeedbackTab
    }
  }
}
