








































































import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import { InputSetups } from '@/mixins/input-setups'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import AutoReplyActions from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/AutoReplyActions.vue'
import {
  AutoReplyAction,
} from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { ButtonType } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import TextButton from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import { cloneDeep } from 'lodash'
import { UserAutoReplyAction } from '@/includes/types/PmConfig.types'
import { ButtonStruct } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";

@Component({
  components: {
    CenteredColumnLayout,
    AutoReplyActions,
    FieldTitle,
    Accordion,
  },
  data() {
    return {
      EditorButtonsHelpView,
      TextButton,
    }
  }
})
export default class AutoReplyActionsPage extends Mixins<ModelSetter, InputSetups, UseFields>(ModelSetter, InputSetups, UseFields) {
  actionsBackup: Array<UserAutoReplyAction> | null = null

  alert = false

  @Watch('buttons')
  buttonsWatcher(buttons: Array<Array<ButtonType & { text: string }>>): void {
    const buttonsText: Array<string> = []
    buttons.forEach((row) => {
      row.forEach((button) => {
        buttonsText.push(button.text)
      })
    })

    const conditions: Array<string> = []

    if (this.$store.state.boardsState.activeBoard) {
      this.$store.state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions!.forEach((action) => {
        action.conditions.forEach((group) => {
          group.forEach((condition) => {
            conditions.push(...condition.values)
          })
        })
      })
    }

    let i = 0
    let alert = false

    while (i < buttonsText.length && !alert) {
      alert = !conditions.some((condition) => condition.includes(buttonsText[i].trim()))
      i++
    }

    this.alert = alert
  }

  get buttons(): Array<Array<ButtonStruct>> {
    if (this.$store.state.boardsState.activeBoard) {
      const initialMessage = this.$store.state.boardsState.activeBoard.config.pm_config.initial_message
      if (initialMessage && initialMessage[0]) {
        return initialMessage[0].buttons
      }
    }
    return []
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  saveConfig(): void {
    this.$store.dispatch('savePostBoardConfig')
  }

  addAction(command: AutoReplyAction): void {
    this.$store.commit('add_reply_action', command)
  }

  removeAction(guid: string): Promise<void> {
    this.$store.commit('remove_reply_action', guid)
    const isExist = this.actionsBackup!.find((a) => a.guid === guid)

    if (isExist) {
      return this.$store.dispatch('savePostBoardConfig')
        .then(() => this.setBackup())
    }
    return new Promise(resolve => resolve())
  }

  saveAction(action: AutoReplyAction): Promise<void> {
    this.$store.commit('update_reply_action', action)
    return this.$store.dispatch('savePostBoardConfig')
      .then(() => this.setBackup())
  }

  setBackup(): void {
    this.actionsBackup = cloneDeep(this.$store.state.boardsState.activeBoard!.config.pm_config.user_auto_reply_actions)
  }

  created(): void {
    if (!this.$store.state.flowsState.flows) {
      this.$store.dispatch('requestFlows', { board_key: this.$store.state.boardsState.activeBoard!.board })
    }
    this.setBackup()

    this.buttonsWatcher(this.buttons)
  }
}
