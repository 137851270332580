













































import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'
import AutoReplyActions from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/AutoReplyActions.vue'
import { AutoReplyAction } from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'

@Component({
  components: {
    AutoReplyActions,
    FieldTitle,
    CenteredColumnLayout,
  }
})
export default class PmCommandsPage extends Vue {
  @Emit('goToBotFeedbackId')
  goToBotFeedbackId(): void {
    return
  }

  get bindFeedbackId(): string | null {
    const id = this.$store.state.boardsState.activeBoard!.config.pm_config.feedback_group_id

    if (id) {
      const admin = this.$store.state.boardsState.activeBoard!.admins.find((a) => a.user_id === id)
      if (admin) {
        return `@${ admin.username }`
      }

      return id
    }

    return null
  }

  removeAction(guid: string): Promise<void> {
    this.$store.commit('remove_pm_command', guid)
    return this.$store.dispatch('savePostBoardConfig')
  }

  saveAction(action: AutoReplyAction): Promise<void> {
    this.$store.commit('update_pm_command', action)
    return this.$store.dispatch('savePostBoardConfig')
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  created(): void {
    if (!this.$store.state.flowsState.flows) {
      this.$store.dispatch('requestFlows', { board_key: this.$store.state.boardsState.activeBoard!.board })
    }
  }
}
