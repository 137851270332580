

















import { Component } from 'vue-property-decorator'
import PrivateMessageSettings from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/PrivateMessageSettings.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { InputSetups } from '@/mixins/input-setups'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { BindTargetByCodeTypes, BindTargetStatus } from '@/includes/types/BindTargetByCode.types'
import BindTargetService from '@/includes/services/BindTargetService'
import { PmConfig } from '@/includes/types/PmConfig.types'

@Component({
  data() {
    return {
      EditorButtonsHelpView,
    }
  },
  components: {
    PrivateMessageSettings,
    CenteredColumnLayout,
  }
})
export default class PrivateMessagesSettingsPage extends InputSetups {
  board = {
    title: '',
    key: '',
  }

  get pmConfig(): PmConfig {
    return this.$store.state.boardsState.activeBoard!.config.pm_config
  }

  bindTargetByCode(args: { type: BindTargetByCodeTypes.BindFeedback } | { type: BindTargetByCodeTypes.Status, code: string }): Promise<any> {
    return new Promise(resolve => {
      BindTargetService.bindTargetByCode('tg', Object.assign(args, { board_key: this.$store.state.boardsState.activeBoard!.board }))
        .then((res) => {
          resolve(res)
          if (res.status === BindTargetStatus.Success) {
            this.$store.dispatch('getBoardConfig', this.$store.state.boardsState.activeBoard!.board)
          }
        })
    })
  }

  saveConfig(config: PmConfig): Promise<void> {
    this.$store.commit('set_private_message_settings', config)
    return this.$store.dispatch('savePostBoardConfig')
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  get adminList():Array<SelectOptionData> {
    return this.$store.state.boardsState.activeBoard?.admins
      .slice()
      .filter(a => a.limits === null)
      .map(a => ({
        value: a.user_id,
        label: `${ a.name } ${ a.username ? '(@' + a.username + ')' : '' }`
      }))
  }

  created(): void {
    if (!this.$store.state.flowsState.flows) {
      this.$store.dispatch('requestFlows')
    }

    const board = this.$store.state.boardsState.activeBoard!

    this.board = {
      key: board.board,
      title: board.title
    }
  }
}
