var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',{staticClass:"pt-2"},[_c('a-card',{staticClass:"mb-5",attrs:{"title":_vm.$t('initial_message_setting_title').toString()}},[_c('a-alert',[_c('div',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('initial_message_setting_help_message', [_vm.$store.state.boardsState.activeBoard.title]).toString())},slot:"description"})]),(_vm.$store.state.boardsState.activeBoard.config.pm_config.initial_message !== null)?_c('multi-message-editor-with-media-input',{staticClass:"mb-2",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.$store.state.boardsState.activeBoard.config.pm_config,
          'key': 'initial_message',
          'hasMedia': true,
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.state.boardsState.activeBoard.board
            }
          },
          'base-api-url': 'api.presscode.app/cabinet',
          'availableButtonsTypes': [ new _vm.TextButton({ i18n: _vm.$i18n }) ],
          'buttonsHelpView': _vm.EditorButtonsHelpView,
        }
      }}}):_vm._e(),_c('div',{staticClass:"flex justify-end"},[_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveConfig}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1),(_vm.alert)?_c('a-alert',{attrs:{"type":"warning","show-icon":"","message":_vm.$t('warning').toString()}},[_c('span',{attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(_vm.$t('auto_reply_actions_page_warning_alert'))+" ")])]):_vm._e(),_c('auto-reply-actions',{attrs:{"auto-reply-action":_vm.$store.state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions,"flow-options":_vm.$store.getters.flowOptions,"empty-flow-button-callback":_vm.gotoFlowsList,"board-key":_vm.$store.state.boardsState.activeBoard.board,"save-action":_vm.saveAction,"remove-action":_vm.removeAction}},[_c('field-title',{attrs:{"slot":"page-title","title-key":"auto_reply_actions"},slot:"page-title"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }